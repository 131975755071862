.ErrorBox {
  background: rgba(255, 0, 0, 0.2);
  border: 1px solid rgba(255, 0, 0, 0.4);
  border-radius: 4px;
  padding: 2px 4px;
  display: inline-block;

  span.title {
    font-size: 10px;
    color: var(--red);
  }

  span.desc {
    font-size: 10px;
    color: var(--text3);

    word-wrap: break-word;
  }
}