body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--bg);
  color: var(--text2);
}

.App {
  .logo {
    height: 28px;
    filter: brightness(3);
  }

  .Menu {
    a {
      color: var(--sub);
    }
  }
}

.list-group-item {
  background: var(--bgSub);
  color: var(--text2);
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-json-view {
  span {
    display: inline-block;
  }
}

h1 {
  font-size: 16px;
  font-weight: 900;
}

h2 {
  font-size: 16px;
}

button {
  cursor: pointer;
  border: none;
  background: none;
  color: inherit;
  font-size: 16px;
  padding: 0;
  margin: 0;
  background: var(--main);
  color: var(--main_);
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 12px;
}

span {
  display: block;
  opacity: 0.8;
  font-size: 13px;
  font-weight: 200;

  &.title {
    width: 100%;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.date {
    opacity: 0.4;
  }
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  color: var(--url);

  &.mail {
    color: var(--url);
  }
}

.item {
  margin-bottom: 12px;
  border-left: 8px solid #000;
  padding-left: 8px;
  margin-left: 2px;
  overflow: hidden;
}

.active {
  opacity: 0.5 !important;
}

.card {
  background: var(--bgSub);

  color: var(--text2);
}