.FinishList {
  .item {
    border-color: green;

    &.clicked {
      background-color: rgb(0, 0, 0, 0.2);
    }

    .version {
      font-size: 10px;
      border: 0.5px solid #fff;
      padding: 1px 4px;
      opacity: 0.3;
      border-radius: 4px;
    }

    &:hover {
      .version {
        opacity: 1;
      }
    }
  }

  position: relative;

  .reload-circle {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    /* Optional: Hintergrund und Schatten für bessere Sichtbarkeit */
    background: #f0f0f0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .circular {
    width: 40px;
    height: 40px;
    transform: rotate(-90deg);
  }

  .path {
    stroke: #000000;
    /* Farbe des Fortschrittskreises */
    stroke-linecap: round;
    animation: progress 10s linear infinite;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0, 126;
    }

    100% {
      stroke-dasharray: 126, 0;
    }
  }

  /* Anpassung des Kreises */
  .circular .path {
    stroke-dasharray: 126;
    stroke-dashoffset: 0;
  }

}