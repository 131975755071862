body.dark-theme img {
  filter: brightness(0.8) contrast(1.2);
}

.bg-sub {
  background: var(--bg);
}

html {
  --bgMid: #f1f0f5;
  --bgUp: #fff;
  --bgDown: #ebebeb;
  --bg: #f5f6f7;
  --bg20: #4f525633;
  --bgSub: #fff;
  --bgSub90: #ffffffe5;
  --black: #000;
  --white: #fff;

  --bgColored: #424eed;
  --bgColored_: #fff;

  --text1: #000;
  --text15: #020818;
  --text2: #414141;
  --text3: #777;
  --text4: #a7a7a7;
  --text5: #dddddd;

  --main: #003493;
  --main95: #003393e3;
  --main_: #eee;
  --sub: #246ef5;
  --sub50: #246df575;
  --sub30: #246df54a;
  --sub_: #fff;

  --sub_b: #246ef588;
  --sub_o1: #3524f513;
  --sub_o01: rgba(53, 36, 245, 0.019) 3;

  --gold: #ff9f43;
  --gold_: #fff;

  --green: #20bf6b;
  --green_: #ffffff;

  --red: #ea2027;
  --red_: #ffffff;

  --fa-primary-color: var(--main);
  --fa-secondary-color: var(--sub);
  --fa-secondary-opacity: 0.5;
}

@media (prefers-color-scheme: dark) {
  img {
    filter: brightness(0.8) contrast(1.2);
  }

  html {
    --bgMid: #111;
    --bgUp: #181818;
    --bgDown: #0f0f0f;
    --bg: #120b34;
    --bg20: #27282a33;
    --bgSub: #03000f;
    --bgSub90: #000000e5;
    --ghost: #1b1b1b;
    --black: #fff;
    --white: #000;

    --bgColored: #424eed;
    --bgColored_: #fff;

    --text1: #fff;
    --text2: #ddd;
    --text3: #aaa;
    --text4: #636363;
    --text5: #242424;

    --gold: #ff9f43;
    --gold_: #fff;

    --red: #ea2027;
    --dpBlue: #060606;
    --fa-primary-color: var(--main);
    --fa-secondary-color: var(--sub);
    --fa-secondary-opacity: 0.5;
  }
}

.theme.red_green {
  --main: #aa0c14;
  --main_: #eee;
  --sub: #168f37;
  --sub_: #fff;
  // --fa-primary-color: var(--main);
  // --fa-secondary-color: var(--sub);
  // --fa-secondary-opacity: 0.5;

  @media (prefers-color-scheme: dark) {
    --main: #fc5c65;
    --main_: #eee;
    --sub: #168f37;
    --sub_: #fff;
    // --fa-primary-color: var(--main);
    // --fa-secondary-color: var(--sub);
    // --fa-secondary-opacity: 0.5;
  }
}

.theme.blue_green {
  --main: #295dac;
  --main_: #eee;
  --sub: #168f37;
  --sub_: #fff;
  // --fa-primary-color: var(--main);
  // --fa-secondary-color: var(--sub);
  // --fa-secondary-opacity: 0.5;

  @media (prefers-color-scheme: dark) {
    --main: #295dac;
    --main_: #eee;
    --sub: #168f37;
    --sub_: #fff;
    // --fa-primary-color: var(--main);
    // --fa-secondary-color: var(--sub);
    // --fa-secondary-opacity: 0.5;
  }
}

.theme.blue_red {
  --main: #477bca;
  --main_: #eee;
  --sub: #fc5c65;
  --sub_: #fff;
  // --fa-primary-color: var(--main);
  // --fa-secondary-color: var(--sub);
  // --fa-secondary-opacity: 0.5;

  @media (prefers-color-scheme: dark) {
    --main: #477bca;
    --main_: #eee;
    --sub: #fc5c65;
    --sub_: #fff;
    // --fa-primary-color: var(--main);
    // --fa-secondary-color: var(--sub);
    // --fa-secondary-opacity: 0.5;
  }
}

.theme.nightblue {
  --main: #250752;
  --main_: #eee;
  --sub: #250752;
  --sub_: #fff;
  // --fa-primary-color: var(--main);
  // --fa-secondary-color: var(--sub);
  // --fa-secondary-opacity: 0.5;

  @media (prefers-color-scheme: dark) {
    --main: #3d0b88;
    --main_: #eee;
    --sub: #3d0b88;
    --sub_: #fff;
    // --fa-primary-color: var(--main);
    // --fa-secondary-color: var(--sub);
    // --fa-secondary-opacity: 0.5;
  }
}

.theme.orange {
  --main: #d59005;
  --main_: #eee;
  --sub: #d59005;
  --sub_: #fff;
  // --fa-primary-color: var(--main);
  // --fa-secondary-color: var(--sub);
  // --fa-secondary-opacity: 0.5;

  @media (prefers-color-scheme: dark) {
    --main: #d59005;
    --main_: #eee;
    --sub: #d59005;
    --sub_: #fff;
    // --fa-primary-color: var(--main);
    // --fa-secondary-color: var(--sub);
    // --fa-secondary-opacity: 0.5;
  }
}

.theme.red {
  --main: #fc5c65;
  --main_: #eee;
  --sub: #fc5c65;
  --sub_: #fff;
  // --fa-primary-color: var(--main);
  // --fa-secondary-color: var(--sub);
  // --fa-secondary-opacity: 0.5;

  @media (prefers-color-scheme: dark) {
    --main: #fc5c65;
    --main_: #eee;
    --sub: #fc5c65;
    --sub_: #fff;
    // --fa-primary-color: var(--main);
    // --fa-secondary-color: var(--sub);
    // --fa-secondary-opacity: 0.5;
  }
}