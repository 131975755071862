.NewEventsList {
  .EventItem {
    border-color: green;
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;

    span.warning {
      color: red;
      font-weight: 400;
      font-size: 12px;
      padding-right: 2px;
    }

    .strapi {
      color: #4945ff;
      font-weight: 800;
      font-size: 12px;
      border-radius: 4px;
    }

    code {
      font-size: 8px;
    }
  }
}